.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  .input-swal {
    width: 400px !important;
  }
  .textarea-swal {
    width: 400px !important;
  }
  .inp-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .label {
      font-size: 15px;
      text-align: left;
    }
  }
}
